// Define your font sizes, line heights, and font weights
$sizes: (
  "xs": $font-size-xs,
  "sm": $font-size-sm,
  "md": $font-size-md,
  "lg": $font-size-lg,
  "xl": $font-size-xl
);

$weights: (
  "300": 300,
  "400": 400,
  "500": 500,
  "600": 600,
  "700": 700,
  "800": 800,
  "900": 900
);

$line-heights: (
  "100": 1,
  "110": 1.1,
  "120": 1.2,
  "130": 1.3,
  "140": 1.4,
  "150": 1.5,
  "160": 1.6,
  "170": 1.7,
  "180": 1.8
);

// Loop through each breakpoint to generate responsive classes
@each $breakpoint in map-keys($grid-breakpoints) {
  $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

  @include media-breakpoint-up($breakpoint) {

    // Text size classes
    @each $size, $value in $sizes {
      .text-#{$size}#{$infix} {
        font-size: $value !important;
      }
    }

    // Font weight classes
    @each $weight, $value in $weights {
      .font-weight-#{$weight}#{$infix} {
        font-weight: $value !important;
      }
    }

    // Line height classes
    @each $lineheight, $value in $line-heights {
      .lh-#{$lineheight}#{$infix} {
        line-height: $value !important;
      }
    }
  }
}

// Weight and italics

.font-weight-300 {
  font-weight: 300 !important;
}
.font-weight-400 {
  font-weight: 400 !important;
}
.font-weight-500 {
  font-weight: 500 !important;
}
.font-weight-600 {
  font-weight: 600 !important;
}
.font-weight-700 {
  font-weight: 700 !important;
}
.font-weight-800 {
  font-weight: 800 !important;
}
.font-weight-900 {
  font-weight: 900 !important;
}

// Text decorations

.text-underline {
  text-decoration: underline;
}
.text-underline-hover {
  &:hover {
    text-decoration: underline;
  }
}
.text-through {
  text-decoration: line-through;
}

// Text size

.text-xs {
  font-size: $font-size-xs !important;
}
.text-sm {
  font-size: $font-size-sm !important;
}
.text-lg {
  font-size: $font-size-lg !important;
}
.text-xl {
  font-size: $font-size-xl !important;
}

// Line heights

.lh-100 {
  line-height: 1;
}
.lh-110 {
  line-height: 1.1;
}
.lh-120 {
  line-height: 1.2;
}
.lh-130 {
  line-height: 1.3;
}
.lh-140 {
  line-height: 1.4;
}
.lh-150 {
  line-height: 1.5;
}
.lh-160 {
  line-height: 1.6;
}
.lh-170 {
  line-height: 1.7;
}
.lh-180 {
  line-height: 1.8;
}

// Letter spacings

.ls-1 {
  letter-spacing: 0.0625rem;
}
.ls-15 {
  letter-spacing: 0.09375rem;
}
.ls-2 {
  letter-spacing: 0.125rem;
}

// Color variations

@each $color, $value in $colors {
  @include text-emphasis-variant('.text-#{$color}', $value);
}
