//
// Profile card
//
.card-profile-image {
  position: relative;
  width: 100%;
  max-width: 140px;
  max-height: 140px;
  margin: 0 auto;
  border-radius: 50%;
  overflow: hidden;
  flex-shrink: 0;

  &::before {
    content: '';
    display: block;
    padding-top: 100%; /* Maintains a square aspect ratio */
  }

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    max-width: 140px;
    max-height: 140px;
    object-fit: cover;
    border-radius: 50% !important; /* Ensures the image itself is circular */
    border: 3px solid $card-bg;
    transform: translate(-50%, -50%) scale(1);
    transition: $transition-base;

    &:hover {
      transform: translate(-50%, -50%) scale(1.03);
    }
  }

  .profile-img-overlay {
    position: absolute;
    bottom: 0;
    right: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba($black, 0.6);
    visibility: hidden;
    opacity: 0;
    transition: $transition-base;
  }

  &:hover {
    .profile-img-overlay {
      visibility: visible;
      opacity: 1;
    }
  }

  &.small {
    max-width: 65px;
    max-height: 65px;

    img {
      max-width: 65px;
      max-height: 65px;
    }
  }
}

.card-profile-stats {
  padding: 1rem 0;

  > div {
    text-align: center;
    margin-right: 1rem;
    padding: 0.875rem;

    &:last-child {
      margin-right: 0;
    }

    .heading {
      font-size: 1.1rem;
      font-weight: bold;
      display: block;
    }
    .description {
      font-size: 0.875rem;
      color: $gray-500;
    }
  }
}

.card-profile-actions {
  padding: 0.875rem;
}
