.video-wrapper {
  position: relative;
  padding-bottom: 50%; /* 16:9 */
  height: 0;
  overflow: hidden;

  &.session {
    height: 56.25vw;
    padding-bottom: 0;
  }
  img {
    position: absolute;
    height: auto;
    max-width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    object-fit: cover;
    min-height: 100%;
  }
  .video-player,
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
@include media-breakpoint-down(md) {
  .video-wrapper {
    padding-bottom: 56.25%; /* 16:9 */
  }
}
