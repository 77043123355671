//
// Dropdown
//

// General styles

.dropdown,
.dropup,
.dropright,
.dropleft {
  display: inline-block;
  .btn {
    text-transform: capitalize;
  }
}



.dropdown-menu {
  min-width: 12rem;

  .dropdown-item {
    padding: 0.3rem 1rem;
    font-size: $font-size-sm;
    display: flex;
    align-items: center;
    text-decoration: none;

    > i,
    > svg {
      margin-right: 1rem;
      font-size: 1rem;
      vertical-align: -2%;
      max-height: 20px;
      font-size: 16px;
      text-align: center;
    }

    img {
      margin-right: 0.5rem;
    }
  }
}

.dropdown-header {
  padding-left: 1rem;
  padding-right: 1rem;
  // color: $gray-100;
  font-size: 0.625rem;
  text-transform: uppercase;
  font-weight: 700;
}

// Media components inside dropdown link

.dropdown-menu {
  a.media {
    > div {
      &:first-child {
        line-height: 1;
      }
    }

    p {
      color: $gray-600;
    }

    &:hover {
      .heading,
      p {
        color: theme-color('default') !important;
      }
    }
  }
}

.dropdown-menu-dark {
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6,
  a {
    color: white;
  }
}

// Size variations

.dropdown-menu-sm {
  min-width: 100px;
  border: $border-radius-lg;
}

.dropdown-menu-lg {
  min-width: 320px;
  border-radius: $border-radius-sm;
}

.dropdown-menu-xl {
  min-width: 420px;
  border-radius: $border-radius-sm;
}
.dropdown-toggle.bg-white {
  &:hover,
  &:focus {
    background: $secondary !important;
  }
}
