.DayPicker {
  width: 100%;

  .DayPicker-Months {
    width: 100%;
    display: inline-flex;
    .DayPicker-Month {
      width: 100%;
      border-collapse: separate;
      border-spacing: 10px;
      margin: 0.6rem 0 0 0;
      .DayPicker-Day {
        width: auto;
        height: auto;
        padding: 0.56rem 0.7rem;
      }
      .DayPicker-Caption {
        padding: 0 1rem;
      }
      .DayPicker-Caption > div {
        font-size: $font-size-base;
        font-weight: 600;
      }

      .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):not(.DayPicker-Day--today):not(.DayPicker-Day--selected) {
        background: rgba(82, 186, 152, 0.04);
      }
      .DayPicker-Day--today {
        color: $primary;
      }
      .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
        position: relative;
        background-color: $primary;
        color: $white;
      }
    }
  }
  &.small {
    width: auto;
    margin: 0 auto;
    display: block !important;
    .DayPicker-Weekday {
      padding: 0.5em 0;
      font-size: 0.65em;
    }
    .DayPicker-Months {
      width: 100%;
      max-width: 229px;
      display: block;
      .DayPicker-Month {
        border-spacing: 5px;
        .DayPicker-Caption {
          padding: 0;
          > div {
            font-size: 1rem;
          }
        }
        .DayPicker-Day {
          padding: 0;
          width: 27px;
          height: 27px;
          min-width: 27px;
          max-width: 27px;
          font-size: 0.75em;
          &.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
            font-weight: normal;
          }
        }
      }
    }
  }
}
.DayPicker-Weekday {
  color: $gray-800 !important;
}

.DayPicker:not(.DayPicker--interactionDisabled)
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background: rgba(82, 186, 152, 0.15) !important;
}
.time-slot {
  transition: all 0.15s ease;
  width: 50%;
  padding: 0rem 0.5rem;
  color: $default;
  font-weight: 600;
  text-align: center;
  border: 1px solid transparent;
  line-height: 50px;
  &.full {
    width: 100%;
    padding: 0rem 0.9rem;
    border-radius: 1.5rem;
    color: $primary;
    background-color: transparent;
    border: 1px solid $primary;
    height: 50px;
    line-height: 50px;
    cursor: pointer;
    &:hover {
      color: $white;
      background-color: $primary;
    }
  }
}
.time-slot-confirm {
  width: 50%;
  height: 50px;
  line-height: 50px;
  color: $white;
  background-color: $primary;
  border: 1px solid $primary;
  transition: all 0.15s ease;
  border-radius: 1.5rem;
  padding: 0rem 0.3rem;
  text-align: center;
  cursor: pointer;

  &.hide {
    width: 0px;
    overflow: hidden;
    padding: 0;
    border: none;
    font-size: 0;
    height: 50px;
    line-height: 50px;
  }
}
.DayPicker-wrapper {
  padding-top: 0.2em;
  .DayPicker-NavBar {
    .DayPicker-NavButton {
      margin-top: -3px !important;
      top: 1.2em;
      right: 1em;
      width: 1em;
      height: 1em;
    }
  }
}

@media (max-width: 470px) {
  .DayPicker {
    width: 285px;
    margin: 0 auto;
    display: block !important;
    .DayPicker-Months {
      width: 100%;
      display: inline-flex;
      .DayPicker-Month {
        border-spacing: 0rem;
        .DayPicker-Caption {
          padding: 0;
        }
        .DayPicker-Day {
          padding: 0;
          width: 40px;
          height: 40px;
          min-width: 40px;
          max-width: 40px;
        }
      }
    }
  }
}
@media (max-width: 350px) {
  .DayPicker {
    width: 100%;
    .DayPicker-Months {
      width: 100%;
      display: inline-flex;
      .DayPicker-Month {
        border-spacing: 0rem;
        .DayPicker-Day {
          padding: 0;
          width: 40px;
          height: 40px;
          min-width: 40px;
          max-width: 40px;
        }
      }
    }
  }
}
