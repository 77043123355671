// Defining variables for dimensions
$dimensions: (42, 50, 75, 95, 100, 105, 150, 155, 200, 250, 275, 300, 350, 400, 450, 500, 550, 600, 650,700,750,800,850,900,950,1000,1050,1100,1150,1200,1250,1300);
$media-breakpoints: (sm, md, lg, xl);

// Helper classes for width, height, and max settings
%width-height-max {
  max-width: 100% !important;
  width: 100% !important;
}

%min-max-width {
  max-width: fit-content !important;
  min-width: auto !important;
}

.transition {
  transition: all 0.15s ease-out 0s !important;
}
.w-0 {
  width: 0 !important;
}

.mw-100 {
  @extend %width-height-max;
}

.mw-auto {
  @extend %min-max-width;
}

.mh-100 {
  max-height: 100% !important;
  min-height: 100% !important;
}

@each $size in $dimensions {
  .min-vh-100-#{$size} {
    min-height: calc(100vh - #{$size}px) !important;
  }

  .vh-100-#{$size} {
    height: calc(100vh - #{$size}px) !important;
  }

  @if $size != 105 { // 105 is used for both max-height and min-height
    .mh-100-#{$size} {
      max-height: calc(100vh - #{$size}px) !important;
    }
  }
  @if $size != 100 { // 105 is used for both max-height and min-height
    .h-#{$size} {
      height: #{$size}px !important;
    }
  } 
}
.mh-checkout {
  max-height: 560px !important;
}

.mh-checkout-subscription {
  max-height: 695px !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vh-100 {
  height: 100vh !important;
}

// Responsive and device-specific classes
@each $breakpoint in $media-breakpoints {
  @include media-breakpoint-up($breakpoint) {
    .min-h-100-#{$breakpoint} {
      min-height: 100vh !important;
    }

    @each $size in $dimensions {
      .mw-#{$size}-#{$breakpoint} {
        max-width: #{$size}px !important;
        width: 100% !important;
        display: inline-block !important;
      }

      .min-h-#{$size}-#{$breakpoint} {
        min-height: #{$size}px !important;
      }

      .vw-100-#{$size}-#{$breakpoint} {
        max-width: calc(100vw - #{$size}px) !important;
        width: 100%;
        display: inline-block !important;
      }
      .mh-#{$size}-#{$breakpoint} {
        max-height: #{$size}px !important;
      }
    }
  }
}

// Scroll behavior
.scroll, .scroll-y {
  overflow-y: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.scroll::-webkit-scrollbar,
.scroll-y::-webkit-scrollbar {
  display: none;
}

.scroll-x {
  overflow-x: auto;
}

.readmore-bg {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 60px;
  background-image: linear-gradient(to bottom, transparent, white);
}
