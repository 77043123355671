.collage-item {
  position: relative;
  cursor: pointer;
  border: none;
  outline: none;
  width: 100%;
  /* padding-top: 75%;  4:3 Aspect Ratio */

  input[type='radio'],
  input[type='checkbox'] {
    width: 24px;
    height: 24px;
    background: $gray-200;
    appearance: none;
    border-radius: 50%;
    right: 10px;
    margin-left: 0;
    z-index: 2;
    text-align: center;
    top: 8px;
    margin-top: 0;
    &:checked {
      background: $primary;
      &:before {
        display: inline-block;
        content: '';
        transform: rotate(45deg);
        border-bottom: 4px solid $white;
        border-right: 4px solid $white;
        border-radius: 2px;
        height: 14px;
        width: 7px;
        line-height: 24px;
        margin-top: 4px;
      }
    }
    + .card {
      border-width: 2px !important;
    }
    &:checked + .card {
      border: 2px solid $primary !important;
    }
    &.no-radio-circle {
      width: 0px;
      height: 0px;
      background: #fff;
    }
  }
  .card {
    position: relative;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    transition: border 0.15s ease;
  }
  &.isImg {
    padding-top: 0;
    .card {
      height: auto;
      flex-direction: column;
      justify-content: inherit;
      align-items: inherit;
      position: relative;
      transition: border 0.15s ease;
    }
  }
  &:hover {
    input[type='radio'],
    input[type='checkbox'] {
      + .card {
        border: 2px solid $primary !important;
      }
    }
  }
}
