// Wrapper for the tooltip content
.tooltip-inner {
  max-width: $tooltip-max-width;
  padding: $tooltip-padding-y $tooltip-padding-x;
  color: $tooltip-color;
  text-align: center;
  background-color: $default;
  @include border-radius($tooltip-border-radius);
}
.tooltip-up,
.tooltip {
  cursor: pointer;
  position: relative;

  //Tooltip text container
  &::before {
    position: absolute;
    bottom: calc(100% + 5px);
    left: 50%;
    transform: translateX(-50%);
    margin: 0 auto;
    background-color: $default;
    border-radius: 5px;
    color: #fff;
    content: attr(data-title);
    white-space: nowrap;
    padding: 0.5rem;
    text-transform: none;
    transition: all 0.5s ease;
    width: max-content;
    z-index: 1;
  }

  //Tooltip arrow
  &::after {
    position: absolute;
    top: -5px;
    left: 50%;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid $default;
    content: ' ';
    font-size: 0;
    line-height: 0;
    margin-left: -5px;
    width: 0;
  }

  //Setting up the transition
  &::before,
  &::after {
    color: #fff;
    font-size: 16px;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    text-align: center;
  }

  //Triggering the transition
  &:hover::before,
  &:hover::after {
    opacity: 1;
    visibility: visible;
    transition: all 0.75s ease;
  }
  &.bottom {
    &::before {
      top: calc(100% + 5px);
      bottom: auto;
    }
    &::after {
      bottom: -5px;
      top: auto;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid $default;
      border-top: none;
    }
  }
}
