.inline-text_copy {
  display: block;
}
.inline-text_copy--active,
.inline-text_input--active {
  font: inherit;
  color: inherit;
  padding: 0;
  background: none;
  border: none;
  //  border-bottom: 1px dashed rgb(233, 236, 239);
  outline: none;
  line-height: 1.8;
  text-align: left;
}

.inline-text_copy--active {
  cursor: pointer;
  position: relative;
  padding: $input-padding-y-lg $input-padding-x;
  i {
    right: 0.7rem;
    top: 1.35rem;
    font-size: 0.8rem;
    opacity: 0.5;
    position: absolute;
  }
  &:hover i {
    opacity: 1;
  }
}

.inline-text_copy--hidden,
.inline-text_input--hidden {
  display: none;
}

.inline-text_input--active {
  border: 1px solid #666666;
  padding: $input-padding-y $input-padding-x;
  text-align: left;
  border-radius: $input-border-radius-lg;
  width: 100%;
}

.text-edit-wrapper {
  display: inline-block;
  position: relative;
  cursor: pointer;
  width: 100%;

  .text-edit-display {
    display: flex;
    align-items: center;

    .text-edit-tag {
      margin: 0;
      display: inline-block;
    }

    .text-edit-icon {
      visibility: hidden;
      font-size: 0.65rem;
      margin-left: 3px;
      color: $default; // Default icon color, change as necessary
    }
  }

  &:hover .text-edit-icon {
    visibility: visible;
  }

  .text-edit-input {
    display: inline-block;
    width: 100%;
    border: 1px solid $primary; // Example border color, change as necessary
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: $default; // Example input text color, change as necessary
    background-color: $white;
    background-clip: padding-box;
    border-radius: $border-radius-md;
    transition: all 0.15s ease-in-out;
  }
}

.autocomplete-wrapper {
  position: relative;
}

.autocomplete-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  max-height: 600px;
  overflow-y: auto;
  box-shadow: $box-shadow-lg;
  background-color: $white;
  z-index: 1000;
  padding: 0;
}

.autocomplete-option {
  padding: $input-padding-y $input-padding-x;
  cursor: pointer;
  list-style: none;

  &:hover,
  &.active {
    background-color: $gray-100;
  }
}

