.st0 {
  fill: $gray-200;
}
#logo2 {
  width: 150px !important;
  height: 150px !important;
  position: relative;
  margin-top: -100px;
}
#banner {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  overflow: hidden;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  z-index: -1;
  margin-bottom: -50px;
  display: contents;
}
#banner svg {
  width: 180px;
}
#banner .fill {
  animation-name: fillAction;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.2, 0.6, 0.8, 0.4);
  animation-duration: 6s;
  animation-fill-mode: forwards;
}
#banner #waveShape {
  animation-name: waveAction;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-duration: 0.6s;
  width: 300px;
  height: 150px;
  fill: #43b593;
}
@keyframes fillAction {
  0% {
    transform: translate(0, 150px);
  }
  80% {
    transform: translate(0, -5px);
  }
  90% {
    transform: translate(0, -5px);
  }
  100% {
    transform: translate(0, 150px);
  }
}
@keyframes waveAction {
  0% {
    transform: translate(-150px, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
$color-fur-light: #52ba98;
$color-fur-dark: #52ba98;
$color-features: #fff;
$color-features-light: #fff;
$color-yarn: #db242a;
$duration: 7s;
$easing: cubic-bezier(0.5, 0, 0.5, 1);
$offset: 0.5s;
$swings: 5 -5 5 -5 5 -5 5 -5 5;
$total-swings: length($swings);

.all-wrap *,
.all-wrap *:before,
.all-wrap *:after {
  box-sizing: border-box;
  position: relative;
  animation-timing-function: $easing;
  animation-fill-mode: both;
}

.all-wrap *:before,
.all-wrap *:after {
  content: '';
  display: block;
}

.all-wrap {
  //  animation: bob $duration $easing infinite both;
}

.all {
  top: 10rem;
  left: calc(50% - 2.5rem);
  position: absolute;
  width: 5rem;
  height: 5rem;
  transform-origin: center -20rem;
  //  animation: swing $duration $easing infinite both;
}

.yarn {
  position: absolute;
  top: 0;
  left: 0;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-image: #000;
  z-index: 1;

  &:before,
  &:after {
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #52ba98;
    top: -1px;
  }

  &:before {
    left: calc(50% + 7px);
    background-color: lighten($color-fur-dark, 10%);
  }
  &:after {
    right: calc(50% + 7px);
    background-color: $color-fur-light;
  }
}

.cat-wrap {
  position: absolute;
  top: 0;
  left: calc(50% - 45px);
  width: 90px;
  height: 130px;
  //  animation: reverse-swing $duration $easing infinite both; // cancel swing
  transform-origin: top center;
}

.cat {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: swing-leg $duration 0.2s infinite both;
  transform-origin: top center;
}

.cat-upper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform-origin: top center;
  z-index: 1;

  .cat-leg {
    position: absolute;
    width: 20px;
    height: 100%;
    background-color: #52ba98;
    z-index: -1;
    background-image: linear-gradient(to right, $color-fur-light, $color-fur-light 20%, $color-fur-dark);

    &:nth-child(1) {
      border-top-left-radius: 100px;
      left: 10px;

      &:after {
        left: 50%;
      }
    }

    &:nth-child(2) {
      border-top-left-radius: 0;
      border-top-right-radius: 100px;
      right: 10px;

      &:after {
        right: 50%;
      }
    }
  }
}

.cat-lower-wrap {
  height: 70%;
  width: 100%;
  position: absolute;
  top: 100%;
  width: 75px;
  left: calc(50% - 37.5px);
  //  animation: reverse-swing $duration .2s infinite both; // cancel swing
  transform-origin: top center;
}

.cat-lower {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 95%;
  animation: swing-leg $duration 0.5s infinite both;
  transform-origin: top center;

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    background-image: radial-gradient(circle at 10px 50px, #52ba98, #52ba98 40%, $color-fur-light 65%, $color-fur-dark);
    z-index: 1;
  }

  .cat-leg,
  .cat-paw {
    z-index: -1;
    position: absolute;
    height: 20px;
    width: 20px;
    animation: swing-leg $duration 0.3s infinite both;
    z-index: 1;
    transform-origin: top center;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-image: linear-gradient(to right, #52ba98, $color-fur-light, $color-fur-dark);
  }

  > .cat-leg {
    bottom: 20px;

    .cat-leg {
      top: 25%;
    }

    + .cat-leg {
      right: 0;
    }
  }

  .cat-paw {
    top: 50%;
    border-radius: 50%;
    background-color: #52ba98;
  }

  .cat-tail {
    position: absolute;
    height: 9px;
    width: 9px;
    animation: swing-tail 7s cubic-bezier(0.5, 0, 0.5, 1) infinite both;
    transform-origin: top center;
    z-index: 0;
    background-image: linear-gradient(to right, #52ba98, #52ba98, #52ba98);
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    transform: rotate3d(-1, -1, -1, 90deg);

    > .cat-tail {
      top: 60%;
      left: 15%;
    }
  }

  > .cat-tail {
    left: 85%;
    top: 77%;
    transform: rotate(80deg);
  }
}

.cat-head {
  width: 95px;
  height: 130px;
  background-image: radial-gradient(circle at 10px 10px, #52ba98, #52ba98 40%, $color-fur-light 65%, $color-fur-dark);
  border-radius: 30px;
  border-top-right-radius: 35px;
  border-top-left-radius: 35px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  top: calc(100% - 45px);
  transform: perspective(10px) rotateX(1deg);
}

.cat-face {
  position: absolute;
  top: 0;
  left: 0;
  height: 77%;
  width: 100%;
  animation: face $duration $easing infinite both;
  transform-style: preserve-3d;
  perspective: 100px;
}

.cat-ears {
  position: absolute;
  top: -3px;
  left: 17%;
  height: 50%;
  width: 70%;
  z-index: -1;
}

.cat-ear {
  width: 17px;
  height: 17px;
  position: absolute;
  //  border-radius: 15px;
  top: 0px;

  &:first-child {
    left: 0;
    transform-origin: top left;
    background-color: #52ba98;
    border-top-right-radius: 90px;
    border-bottom-right-radius: 28px;
    border-bottom-left-radius: 48px;
    transform: rotate(29deg);

    &:before {
      background-color: #d7ebfb;
      border-radius: 15px;
      left: 2.5px;
      top: 3px;
      border-top-left-radius: 18px;
      border-bottom-right-radius: 62px;
    }
  }

  &:last-child {
    right: 0;
    transform-origin: top right;
    background-color: #4eb996;
    border-top-left-radius: 90px;
    border-bottom-left-radius: 28px;
    border-bottom-right-radius: 48px;
    transform: rotate(-29deg);

    &:before {
      right: 2.5px;
      background-color: #e0f0fc;
      border-radius: 15px;
      top: 4px;
      border-top-right-radius: 18px;
      border-bottom-left-radius: 62px;
    }
  }

  &:before {
    height: 8px;
    width: 8px;
    top: 10px;
    position: absolute;
    background-color: #52ba98;
  }
}

.cat-eyes {
  position: absolute;
  top: 50%;
  width: 100%;
  height: 6px;
  animation: blink $duration step-end infinite both;

  &:before,
  &:after {
    position: absolute;
    height: 6.5px;
    width: 14px;
    border: 2.5px solid #fff;
    border-bottom: 0;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }
  &:before {
    left: 20px;
  }
  &:after {
    right: 20px;
  }
}

.cat-mouth {
  position: absolute;
  height: 4px;
  width: 20px;
  top: 70%;
  left: calc(50% - 10px);
  &:before {
    position: absolute;
    border: 2px solid #fff;
    border-top: 0;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 8px;
    height: 4px;
    width: 16px;
    width: 50%;
    left: 1px;
    transform: rotate(-24deg);
  }
  &:after {
    position: absolute;
    border: 2px solid #fff;
    border-top: 0;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 16px;
    height: 4px;
    width: 16px;
    width: 50%;
    right: 1px;
    transform: rotate(24deg);
  }
}

.cat-whiskers {
  width: 50%;
  height: 8px;
  position: absolute;
  bottom: 25%;
  left: 25%;
  transform-style: preserve-3d;
  perspective: 60px;

  &:before,
  &:after {
    position: absolute;
    height: 100%;
    width: 30%;
    border: 2px solid $color-features-light;
    border-left: none;
    border-right: none;
  }
  &:before {
    right: 100%;
    transform-origin: right center;
    transform: rotateY(70deg) rotateZ(-10deg);
  }
  &:after {
    left: 100%;
    transform-origin: left center;
    transform: rotateY(-70deg) rotateZ(10deg);
  }
}

@function swing-rotate($val) {
  @return rotate($val * 1deg);
}
@function swing-face($val) {
  @return translateX($val * 1px);
}

@mixin swing($mult: 1, $fn: swing-rotate) {
  @for $i from 1 through $total-swings {
    $swing: nth($swings, $i);
    $kfr: ($i - 1) / ($total-swings - 1);

    #{percentage($kfr)} {
      transform: call($fn, $swing * $mult);
    }
  }
}

@keyframes bob {
  $down: true;
  @for $i from 1 through $total-swings * 2 - 1 {
    // $swing: nth($swings, $i);
    $kfr: ($i - 1) / ($total-swings * 2 - 1 - 1);
    $delta: 0.4rem;
    $distance: $delta;
    @if ($down) {
      $down: false;
      $distance: $delta;
    } @else {
      $down: true;
      $distance: -$delta;
    }

    #{percentage($kfr)} {
      transform: translateY($distance);
    }
  }
}

@keyframes swing {
  @include swing();
}

@keyframes swing-leg {
  @include swing(0.1);
}

@keyframes swing-tail {
  0% {
    transform: rotateZ(-11deg);
  }

  12.5% {
    transform: rotateZ(-13deg);
  }
  25% {
    transform: rotateZ(-11deg);
  }
  37.5% {
    transform: rotateZ(-13deg);
  }
  50% {
    transform: rotateZ(-11deg);
  }
  62.5% {
    transform: rotateZ(-13deg);
  }
  75% {
    transform: rotateZ(-11deg);
  }
  87.5% {
    transform: rotateZ(-13deg);
  }
  100% {
    transform: rotateZ(-11deg);
  }
}

@keyframes reverse-swing {
  @include swing(-1);
}

@keyframes face {
  @include swing(-0.7, swing-face);
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

// Loader - Chat Bubble

.chat-bubble {
  background-color: $gray-400;
  padding: 8px 14px;
  -webkit-border-radius: 20px;
  -webkit-border-bottom-left-radius: 2px;
  -moz-border-radius: 20px;
  -moz-border-radius-bottomleft: 2px;
  border-radius: 20px;
  border-bottom-left-radius: 2px;
  display: inline-block;
  z-index: 10;
  .typing {
    align-items: center;
    display: flex;
    height: 17px;
    .dot {
      animation: mercuryTypingAnimation 1.8s infinite cubic-bezier(0.8, 0.22, 0.32, 0.84);
      background-color: $gray-800; //rgba(20,105,69,.7);
      border-radius: 50%;
      height: 5px;
      margin-right: 4px;
      vertical-align: middle;
      width: 5px;
      display: inline-block;
      &:nth-child(1) {
        animation-delay: 200ms;
      }
      &:nth-child(2) {
        animation-delay: 300ms;
      }
      &:nth-child(3) {
        animation-delay: 400ms;
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

@keyframes mercuryTypingAnimation {
  0% {
    transform: translateY(0px);
    background-color: rgba(47, 49, 54, 0.9); // rgba(20,105,69,.7);
  }
  28% {
    transform: translateY(-6px);
    background-color: rgba(47, 49, 54, 0.6); //rgba(20,105,69,.4);
  }
  44% {
    transform: translateY(0px);
    background-color: rgba(47, 49, 54, 0.5); //rgba(20,105,69,.2);
  }
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner,
.centered-spinner {
  width: 25px;
  display: inline-block;
  &:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 0%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    border-radius: 50%;
    border-top: 2.5px solid $primary;
    border-right: 2.5px solid transparent;
    animation: spinner 1s ease infinite;
  }
  &.white {
    &:before {
      border-top: 2.5px solid $white;
      left: 1.05rem;
    }
  }
}
