//
// Modal
//

.modal-title {
  font-size: $modal-title-font-size;
}

// Fluid modal

.modal-fluid {
  .modal-dialog {
    margin-top: 0;
    margin-bottom: 0;
  }

  .modal-content {
    border-radius: 0;
  }
}

// Background color variations

@each $color, $value in $theme-colors {
  .modal-#{$color} {
    @include modal-variant($value);
  }
}
.customer-modal-header{
  .modal-title{
    display: flex;
    justify-content: space-between; /* Aligns content to the start (left) */
    align-items: center;
    flex-grow: 1;
  }
}