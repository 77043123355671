.checkmark-full {
  color: #fff;
  font-family: 'Font Awesome 5 Free';
  font-size: 13px;
  font-weight: 900;
  line-height: 20px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
}
.checkmark-full:before {
  display: inline-block;
  content: '';
  transform: rotate(45deg);
  height: 12px;
  width: 7px;
  border-bottom: 3px solid #fff;
  border-right: 3px solid #fff;
  line-height: 24px;
  margin-top: 4px;
}
.checkmark-full.x-sign:before,
.checkmark-full.x-sign:after {
  position: absolute;
  left: 0;
  left: 10.5px;
  margin: auto;
  top: 5px;
  content: ' ';
  height: 15px;
  width: 2.5px;
  background-color: #fff;
  border: none;
}
.checkmark-full.x-sign:before {
  transform: rotate(45deg);
}
.checkmark-full.x-sign:after {
  transform: rotate(-45deg);
}
.checkmark-full.outline:before {
  border-bottom: 5px solid $success;
  border-right: 5px solid $success;
  border-radius: 4px;
  height: 20px;
  width: 11px;
}
.checkmark-full.x-sign.outline:before,
.checkmark-full.x-sign.outline:after {
  background-color: $gray-400;
  height: 20px;
  width: 3px;
  border-radius: 0;
  border: none;
}

.pricing-table {
  position: relative;
  font-family: $font-family-base;
  overflow-y: hidden;
  overflow-x: scroll;
  background: #fff;

  thead,
  tr {
    display: flex;
    min-width: 462px;
  }
  tr,
  th,
  td {
    flex: 1;
  }
  td:first-child,
  th:first-child {
  }
  tr {
    &.hover-secondary {
      &:hover {
        td {
          background: $gray-100;
        }
      }
    }
  }

  &.table th,
  &.table td {
    font-size: 1em;
    white-space: normal;
  }
  tbody {
    display: flex;
    flex-direction: column;
    vertical-align: top;
  }
  th {
    margin-top: auto;
  }
  thead {
    box-shadow: 0 16px 6px -10px rgba(136, 152, 170, 0.15);
  }
  &.table thead th {
    font-size: 0.92em;
  }
  &.outlined {
    border: 1px solid $gray-300;
    thead {
      border-bottom: 1px solid $gray-300;
      box-shadow: none;

      th {
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: capitalize;
        border-right: 1px solid $gray-300;
        border-bottom: none;
        background: $gray-100;
        h4,
        h3 {
          margin: 0;
        }
        input[type='text'] {
          text-align: center;
        }
      }
      th:first-child {
        background: $gray-100;
      }
      th:last-child {
        border-right: none;
      }
    }
    tbody {
      position: relative;
      td {
        border-right: 1px solid $gray-300;
        display: flex;
        align-content: center;
        justify-content: flex-start;
        width: 33%;
        flex-basis: 33%;
        span {
          align-self: center;
        }
      }
      td:first-child {
        background: $gray-100;
      }
      td:last-child {
        border-right: none;
      }
      .dropdown {
        display: block;
        width: 100%;
        button {
          border-radius: 0px;
          width: 100%;
          height: 100%;
        }
      }
      .pricing-overlay {
        position: absolute;
        background: rgba(255, 255, 255, 0.95);
        width: 33%;
        height: calc(100% - 64.19px);
        top: 0;
        right: 0;
        bottom: 0;
        padding: $input-padding-y $input-padding-x;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        button {
          text-transform: capitalize;
        }
      }
    }
  }
}

@include media-breakpoint-up(sm) {
  .pricing-table {
    h2 {
      font-size: 1rem;
    }
  }
}
@include media-breakpoint-up(xl) {
  .pricing-table {
    h2 {
      font-size: 1.32812rem;
    }
  }
}

.custom-control-input:checked + label.custom-radio {
  background-color: $gray-100;
}
.custom-control-input + label.custom-radio:last-child {
  border-top: none;
}
.custom-control-input:checked + .custom-radio .custom-control-label:before {
  color: $primary;
  background-color: $primary;
  box-shadow: 0 3px 2px rgba(233, 236, 239, 0.05);
}
.custom-control-input:checked + .custom-radio .custom-control-label:after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
}

// Upgrade Page - Pricing Table
.price-table {
  width: 100%;
  border-collapse: collapse;
  border: 0 none;

  tr {
    &:not(:first-child) {
      border-bottom: 1px solid #d2d5d9;
    }

    td {
      border-left: 1px solid #d2d5d9;
      padding: 10px 24px;
      font-size: 14px;

      &:not(:first-child) {
        text-align: center;
      }
    }

    &:nth-child(even) {
      background-color: #ffffff;
    }

    transition: all 0.42s cubic-bezier(0.19, 1, 0.22, 1) 0s;

    &:first-child td:not(:first-child) {
      //      border-bottom: 1px solid #d2d5d9;
    }

    border-left: 1px solid #d2d5d9;
    border-right: 1px solid #d2d5d9;

    &:not(:first-child):hover {
      background-color: #efefef;
    }

    &.price-table-head:hover,
    &.price-table-head-pricing:hover,
    &:last-child:hover {
      background-color: transparent;
    }

    &:first-child td {
      &:last-child {
        //        border-right: 1px solid transparent;
      }

      &:first-child {
        border-left: 1px solid transparent;
        border-bottom: 1px solid transparent;
      }
    }

    &:nth-child(2) td:first-child,
    &:last-child td:first-child {
      border-left: 1px solid transparent;
      border-bottom: 1px solid transparent;
    }

    &:nth-child(3) td:first-child {
      border-left: 1px solid transparent;
    }
  }

  .fa-check {
    color: #52ba98;
  }

  .fa-times {
    color: #d8d6e3;
  }

  tr {
    &:nth-child(2n) td:nth-child(3) {
      background-color: rgba(246, 249, 252, 0.46);
    }

    td:nth-child(3) {
      background-color: rgba(246, 249, 252, 0.46);
    }
  }

  td.price-table-popular {
    border-top: 3px solid #ff9009;
  }

  tr.price-table-head {
    td {
      padding-top: 20px;
      font-size: 20px;
    }

    border-bottom: none;
  }

  td.price {
    padding: 16px 24px;
    font-size: 15px;
    font-weight: 500;

    span {
      font-size: 38px;
      font-weight: 600;
      line-height: 38px;
      margin-right: 3px;
    }

    sup {
      vertical-align: top;
      padding-left: 25px;
      margin-right: 3px;
      top: 8px;
    }

    a {
      background-color: transparent;
      color: #52ba98;
      border: 1px solid #52ba98;
      padding: 12px 0px;
      width: 180px;
      margin-top: 16px;
      font-weight: 600;
      display: inline-block;
      text-decoration: none;

      &.btn-highlight {
        background-color: #52ba98;
        color: #fff;
      }
    }
  }

  .price-blank {
    border: 0 none;
  }
}

/* Highlighted column */

.tooltip-down {
  cursor: pointer;
  position: relative;

  &:hover {
    &::before,
    &::after {
      opacity: 1;
      visibility: visible;
      transition: all 0.75s ease;
      z-index: 1000000;
    }
  }

  &::before,
  &::after {
    color: #fff;
    font-size: 14px;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    text-align: center;
  }

  &::before {
    position: absolute;
    top: calc(100% + 5px);
    left: 50%;
    transform: translateX(-50%);
    margin: 0 auto;
    background-color: #fff;
    border-radius: 5px;
    color: #222f3f;
    content: attr(data-title);
    padding: 0.5rem;
    text-transform: none;
    transition: all 0.5s ease;
    width: 300px;
    text-align: left;
    display: block;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }

  &::after {
    position: absolute;
    bottom: -5px;
    left: 50%;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #fff;
    content: ' ';
    font-size: 0;
    line-height: 0;
    margin-left: -5px;
    width: 0;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  }
}

/* Togggler */

.toggle {
  display: inline-block;
  vertical-align: middle;
  margin: 10px;
}

.toggler {
  display: inline-block;
  vertical-align: middle;
  margin: 10px;
  transition: 0.2s;
  font-weight: bold;
  cursor: pointer;
}

.toggler--is-active {
  color: #52ba98;
}

.b {
  display: block;
}

.hide {
  display: none;
}

.toggle {
  position: relative;
  width: 80px;
  height: 35px;
  border-radius: 100px;
  background-color: #52ba98;
  overflow: hidden;
  box-shadow: inset 0 0 2px 1px rgba(0, 0, 0, 0.05);
}

.check {
  position: absolute;
  display: block;
  cursor: pointer;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 6;

  &:checked ~ .switch {
    right: 2px;
    left: 57.5%;
    transition: 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    transition-property: left, right;
    transition-delay: 0.08s, 0s;
  }
}

.switch {
  position: absolute;
  left: 2px;
  top: 2px;
  bottom: 2px;
  right: 57.5%;
  background-color: #fff;
  border-radius: 36px;
  z-index: 1;
  transition: 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition-property: left, right;
  transition-delay: 0s, 0.08s;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
  .main {
    width: 100%;
  }

  .table-container {
    width: 100%;
    overflow-x: scroll;
  }

  .price-table {
    width: 800px;
  }
}

@media (min-width: 992px) {
  .w-lg-720 {
    min-width: 720px;
  }

  .w-lg-930 {
    min-width: 930px;
  }
}
