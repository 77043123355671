$type-0: #2c3e50 !default;
$type-1: #c44569 !default;
$type-2: #0097e6 !default;
$type-3: #f39c12 !default;
$type-4: #6c5ce7 !default;
$type-5: #60a3bc !default;
$type-6: #6ab04c !default;
$type-7: #eb4d4b !default;
$type-8: #e17055 !default;
$type-9: #f78fb3 !default;

.rbc-time-view {
  border: none !important;
  padding-right: 15px;
}

.rbc-month-view {
  min-height: calc(100vh - 270px) !important;
  flex: none !important;
  border: none !important;
}
.rbc-today {
  background-color: rgba(82, 186, 152, 0.15) !important;
}

.rbc-current-time-indicator {
  background-color: $primary !important;
  height: 2px;
  &:before {
    position: absolute;
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 5px;
    left: -6px;
    top: 50%;
    transform: translateY(-50%);
    background: $primary;
  }
}

.rbc-off-range-bg {
  background: $gray-200 !important;
}
.calendar-container {
  overflow-x: clip;
  .rbc-calendar {
    .rbc-toolbar {
      position: sticky;
      top: 50px;
      background: white;
      z-index: 1015;
      padding: 20px 25px 20px;
      margin-bottom: 0px !important;
      button {
        color: #2f3136 !important;
        background-color: $secondary !important;
        box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08) !important;
        border: 1px solid transparent;
        letter-spacing: 0.025em;
        padding: 0.525rem 1.05rem;
        font-size: 0.92rem;
        line-height: 1.5;
        border-radius: 1.5rem;
      }
      .btn-outline-secondary {
        color: #525f7f !important;
        border-color: transparent !important;
        background: transparent !important;
        box-shadow: none !important;
        &:hover {
          color: #2f3136 !important;
          background-color: $secondary !important;
          border-color: $secondary !important;
        }
      }
      .btn-outline-primary {
        color: $primary !important;
        background-color: transparent !important;
        background-image: none !important;
        border-color: $primary !important;
        box-shadow: none !important;
        &:hover {
          color: white !important;
          background-color: $primary !important;
        }
      }
      .btn-primary {
        color: $white !important;
        background-color: $primary !important;
        background-image: none !important;
        border-color: $primary !important;
        &:hover {
          color: white !important;
          background-color: $primary !important;
        }
      }
      .btn-sm {
        padding: 0 0.3rem;
        font-size: 1.1rem;
        line-height: 2.2;
        border-radius: 1.5rem;
      }
      .dropdown-menu {
        .dropdown-item {
          box-shadow: none !important;
          border-radius: 0;
          background-color: transparent !important;
          padding: 0.3rem 1rem;
          font-size: 0.9296875rem;
          &:hover,
          &:focus {
            color: #232528 !important;
            text-decoration: none;
            background-color: #f6f9fc !important;
          }
        }
      }

      button:active,
      button.rbc-active {
        background-color: $primary !important;
        border-color: $gray-200 !important;
        color: $white !important;
      }

      .rbc-toolbar-label {
        font-weight: 600;
        font-size: 17px;
      }
    }
  }
  .rbc-agenda-empty {
    padding: 20px 25px 20px;
    text-align: center;
    min-height: 100vh;
  }
  @include media-breakpoint-down(md) {
    overflow-x: auto;
    .rbc-calendar {
      min-width: 1000px;
    }
  }
}
@include media-breakpoint-down(sm) {
  .calendar-container {
    .vh-100-50 {
      height: calc(100vh - 103px) !important;
    }
  }
  .rbc-toolbar {
    .btn-primary {
      display: none;
    }
    .rbc-toolbar-label {
      display: block;
      width: 100%;
      padding: 0 15px 15px !important;
    }
  }
  .day-view {
    .rbc-calendar {
      min-width: 100%;
    }
  }
}
.rbc-timeslot-group {
  border-color: $gray-200 !important;
}

.rbc-event {
  background-color: $gray-500 !important;
}

.rbc-time-view .rbc-row {
  min-height: 60px !important;
  position: relative;
  background: $gray-100;
  box-shadow: -10px 15px 1rem 0px rgba(136, 152, 170, 0.15);
  &.rbc-time-header-cell {
    min-height: 65px !important;
  }
}

.rbc-header {
  border-bottom: 0px !important;
  font-weight: normal !important;
  padding: 0 !important;
  flex: 1 1 14.285714285714286% !important;
  &.rbc-today {
    a,
    .rbc-button-link {
      background-color: rgb(82, 186, 152) !important;
      color: $white !important;
      &:after {
        border-left: none;
      }
    }
  }
}
.rbc-header + .rbc-header.rbc-today {
  border-left: 1px solid #ddd !important;
}

.rbc-month-header,
table.rbc-agenda-table thead {
  position: sticky;
  top: 131px;
  background: #f6f9fc;
  z-index: 1014;
  border-bottom: 1px solid #ddd;
  padding: 0px;
  box-shadow: -10px 15px 1rem 0px rgba(136, 152, 170, 0.15);
  .rbc-header {
    border-bottom: 0px !important;
    font-weight: normal !important;
    padding: 0 15px !important;
    flex: 1 1 14.2857142857% !important;
    line-height: 35px;
    border-left: 1px solid #ddd !important;
    &:first-child {
      border-left: none !important;
    }
  }
}
@include media-breakpoint-down(lg) {
  .rbc-month-header {
    top: 0;
  }
}
.rbc-off-range {
  color: #8b9898 !important;
}
.rbc-time-slot {
  border-top: none !important;
  margin-top: -14px;
}

.rbc-time-header {
  position: sticky;
  top: 131px;
  background: $gray-100;
  z-index: 1014;
  border-bottom: 1px solid #ddd;
  padding: 0px;
}

.rbc-time-header-content {
  border-left: none !important;
}

.rbc-label {
  background: $gray-100;
  font-size: 85%;
  padding: 0 5px 0 10px !important;
  margin: 0 15px 0 -5px;

  &.rbc-time-header-gutter {
    margin: 0;
    background: $gray-100;
    z-index: 1;
  }
}

.rbc-time-column .rbc-timeslot-group:first-child {
  font-size: 0;
}

.rbc-header a,
.rbc-header .rbc-button-link {
  padding: 0 5px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  white-space: pre-line;
  line-height: 22px;
  font-size: 17px;

  &:after {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    bottom: 0px;
    border-left: 1px solid #ddd;
  }
  &:hover {
    background: rgba(82, 186, 152, 0.15);
  }
}

.rbc-time-content {
  border-top: 1px solid transparent !important;
  > .rbc-time-gutter.rbc-time-column {
    padding-left: 15px;
    background: $gray-100;
  }
}

.rbc-time-view .rbc-allday-cell {
  display: none;
}

.rbc-month-row {
  flex-basis: auto !important;
}

.rbc-date-cell {
  &:hover {
    background: rgba(82, 186, 152, 0.15);
  }
  &.rbc-now {
    color: rgba(82, 186, 152);
    font-weight: 700 !important;
  }
  > .rbc-button-link {
    width: 100%;
    height: 36px;
    text-align: center;
  }
}
.rbc-month-row {
  .rbc-date-cell {
    padding: 0px !important;
  }
}
.rbc-row-content {
  min-height: 100px;
}
.rbc-agenda-view table.rbc-agenda-table .rbc-agenda-time-cell {
  border-left: 1px solid #ddd;
}

.rbc-agenda-view table.rbc-agenda-table tbody > tr > td {
  padding: 15px 10px !important;
}

.rbc-agenda-view table.rbc-agenda-table thead > tr > th {
  text-align: center !important;
}

.rbc-agenda-view table.rbc-agenda-table thead > tr > .rbc-header + .rbc-header {
  border-left: 1px solid #ddd !important;
}

.rbc-agenda-date-cell {
  font-weight: 600;
}

.rbc-day-slot {
  .rbc-events-container {
    margin-right: 0px !important;
    .rbc-event,
    .rbc-background-event {
      min-height: 0px !important;
      flex-flow: column-reverse nowrap !important;
      // overflow: unset !important;
      border: 1px solid transparent !important;
      box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);

      .rbc-event-label {
        font-size: 10px !important;
        flex: auto !important;
        width: 100%;
        height: auto;
        overflow: hidden;
        display: none;
      }

      .rbc-event-content {
        white-space: nowrap;
        line-height: 1.6;
        height: auto;
        text-overflow: ellipsis;
        flex: none;
        width: 100%;
        overflow: hidden;
        font-size: 90%;
      }
      &.meeting-type-0 {
        background: $type-0 !important;
      }
      &.meeting-type-1 {
        background: $type-1 !important;
      }
      &.meeting-type-2 {
        background: $type-2 !important;
      }
      &.meeting-type-3 {
        background: $type-3 !important;
      }
      &.meeting-type-4 {
        background: $type-4 !important;
      }
      &.meeting-type-5 {
        background: $type-5 !important;
      }
      &.meeting-type-6 {
        background: $type-6 !important;
      }
      &.meeting-type-7 {
        background: $type-7 !important;
      }
      &.meeting-type-8 {
        background: $type-8 !important;
      }
      &.meeting-type-9 {
        background: $type-9 !important;
      }
    }
  }
}

.rbc-event,
.rbc-day-slot .rbc-background-event {
  &.meeting-type-0 {
    background: $type-0 !important;
  }
  &.meeting-type-1 {
    background: $type-1 !important;
  }
  &.meeting-type-2 {
    background: $type-2 !important;
  }
  &.meeting-type-3 {
    background: $type-3 !important;
  }
  &.meeting-type-4 {
    background: $type-4 !important;
  }
  &.meeting-type-5 {
    background: $type-5 !important;
  }
  &.meeting-type-6 {
    background: $type-6 !important;
  }
  &.meeting-type-7 {
    background: $type-7 !important;
  }
  &.meeting-type-8 {
    background: $type-8 !important;
  }
  &.meeting-type-9 {
    background: $type-9 !important;
  }
}

.rbc-timeslot-group {
  min-height: 60px !important;
}
.calendar-week-view {
  .rbc-events-container > .rbc-event {
    &::before,
    &::after {
      color: #fff;
      font-size: 16px;
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
      text-align: center;
      z-index: 1000;
    }

    &::before {
      position: absolute;
      bottom: calc(100% + 5px);
      left: 50%;
      transform: translateX(-50%);
      margin: 0 auto;
      background-color: #2f3136;
      border-radius: 5px;
      color: #fff;
      content: attr(title);
      white-space: nowrap;
      padding: 0.5rem;
      text-transform: none;
      transition: all 0.5s ease;
      width: max-content;
      z-index: 1001;
    }

    &::after {
      position: absolute;
      top: -5px;
      left: 50%;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid #2f3136;
      content: ' ';
      font-size: 0;
      line-height: 0;
      margin-left: -5px;
      width: 0;
    }

    &:hover:before,
    &:hover:after {
      opacity: 1;
      visibility: visible;
      transition: all 0.75s ease;
    }

    .rbc-event-content {
      max-height: 100%;
    }
  }
}

.calendar-week-view {
  .rbc-event-label {
    font-size: 10px !important;
    flex: auto !important;
  }

  .rbc-event-content {
    font-size: 12px !important;
    white-space: nowrap;
    line-height: 1;
    height: auto;
    text-overflow: ellipsis;
  }
}
.day-view {
  .rbc-toolbar {
    box-shadow: -10px 15px 1rem 0px rgba(136, 152, 170, 0.15);
  }
}
.calendar-day-view {
  .rbc-timeslot-group {
    min-height: 135px !important;
  }
  .rbc-event,
  .rbc-background-event {
    .rbc-event-content {
      font-size: 15px !important;
    }
  }
}
.rbc-agenda-event-cell {
  cursor: pointer;
  text-decoration: underline;
}

.calendar-sidebar {
  position: relative;
  z-index: 1020;
  @include media-breakpoint-down(md) {
    position: absolute;
    height: 100%;
  }
}
@include media-breakpoint-down(md) {
  .rbc-toolbar {
    top: 0 !important;
  }
  .rbc-time-header {
    top: 0px;
  }
  .rbc-time-view {
    margin-top: 0;
  }
}
@include media-breakpoint-down(sm) {
  .rbc-toolbar {
    top: 0px !important;
    width: 100vw;
    position: fixed !important;
    top: 100px !important;
    border-bottom: 1px solid #ddd;
  }
  .rbc-time-header {
    top: 0px;
  }
  .rbc-time-view,
  .rbc-month-view,
  .rbc-agenda-view {
    margin-top: 123px;
  }
  .rbc-agenda-view {
    max-width: 100vw;
  }
}
@include media-breakpoint-down(sm) {
  .calendar-sidebar {
    .top-50 {
      top: 100px !important;
    }
  }
}
