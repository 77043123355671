//
// Pricing card
//

.card-pricing {
  .card-header {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .list-unstyled li {
    padding: 0.5rem 0;
  }
}

.card-pricing.popular {
  z-index: 1;
  border: 3px solid theme-color('primary') !important;
}

@include media-breakpoint-up(md) {
  .card-pricing.zoom-in {
    z-index: 1;
    transform: scale(1.01);
  }
}
.ribbon-corner {
  --tor-ribbon-size: 3.5em;
  background: linear-gradient(87deg, #ff9009 0, #ffce2a 100%);
  color: #fff;
  position: absolute;
  top: -1.25em;
  right: 0;
  left: 0;
  margin: auto;
  width: calc((var(--tor-ribbon-size) + 2em) * 2);
  height: 2.5em;
  font-size: 0.8rem;
  line-height: 2.5em;
  font-weight: 600;
  border-radius: $input-border-radius-sm;
  text-transform: uppercase;
}
