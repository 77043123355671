// stylelint-disable selector-no-qualifying-type

//
// Base styles
//
$type-0: #2c3e50 !default;
$type-1: #c44569 !default;
$type-2: #0097e6 !default;
$type-3: #f39c12 !default;
$type-4: #6c5ce7 !default;
$type-5: #60a3bc !default;
$type-6: #6ab04c !default;
$type-7: #eb4d4b !default;
$type-8: #e17055 !default;
$type-9: #f78fb3 !default;

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap; // For form validation feedback
  align-items: stretch;
  width: 100%;

  > .form-control,
  > .form-control-plaintext,
  > .custom-select,
  > .custom-file {
    position: relative; // For focus state's z-index
    flex: 1 1 0%;
    min-width: 0; // https://stackoverflow.com/questions/36247140/why-dont-flex-items-shrink-past-content-size
    margin-bottom: 0;

    + .form-control,
    + .custom-select,
    + .custom-file {
      margin-left: -$input-border-width;
    }
  }

  // Bring the "active" form control to the top of surrounding elements
  > .form-control:focus,
  > .custom-select:focus,
  > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3;
  }

  // Bring the custom file input above the label
  > .custom-file .custom-file-input:focus {
    z-index: 4;
  }

  > .form-control,
  > .custom-select {
    &:not(:last-child) {
    }
    &:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  // Custom file inputs have more complex markup, thus requiring different
  // border-radius overrides.
  > .custom-file {
    display: flex;
    align-items: center;

    &:not(:last-child) .custom-file-label,
    &:not(:last-child) .custom-file-label::after {
      //      @include border-right-radius(0);
    }
    &:not(:first-child) .custom-file-label {
      //      @include border-left-radius(0);
    }
  }
}

// Prepend and append
//
// While it requires one extra layer of HTML for each, dedicated prepend and
// append elements allow us to 1) be less clever, 2) simplify our selectors, and
// 3) support HTML5 form validation.

.input-group-prepend,
.input-group-append {
  display: flex;

  // Ensure buttons are always above inputs for more visually pleasing borders.
  // This isn't needed for `.input-group-text` since it shares the same border-color
  // as our inputs.
  .btn {
    position: relative;
    z-index: 2;

    &:focus {
      z-index: 3;
    }
  }

  .btn + .btn,
  .btn + .input-group-text,
  .input-group-text + .input-group-text,
  .input-group-text + .btn {
    margin-left: -$input-border-width;
  }
}

.input-group-prepend {
  margin-right: -$input-border-width;
}
.input-group-append {
  margin-left: -$input-border-width;
}

// Textual addons
//
// Serves as a catch-all element for any text or radio/checkbox input you wish
// to prepend or append to an input.

.input-group-text {
  display: flex;
  align-items: center;
  padding: $input-padding-y $input-padding-x;
  margin-bottom: 0; // Allow use of <label> elements by overriding our default margin-bottom
  @include font-size($input-font-size); // Match inputs
  font-weight: $font-weight-normal;
  line-height: $input-line-height;
  color: $input-group-addon-color;
  text-align: center;
  white-space: nowrap;
  background-color: $input-group-addon-bg;
  border: $input-border-width solid $input-group-addon-border-color;
  @include border-radius($input-border-radius);

  // Nuke default margins from checkboxes and radios to vertically center within.
  input[type='radio'],
  input[type='checkbox'] {
    margin-top: 0;
  }
}

// Sizing
//
// Remix the default form control sizing classes into new ones for easier
// manipulation.

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: $input-height-lg;
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: $input-padding-y-lg $input-padding-x-lg;
  @include font-size($input-font-size-lg);
  line-height: $input-line-height-lg;
  @include border-radius($input-border-radius-lg);
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: $input-height-sm;
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: $input-padding-y-sm $input-padding-x-sm;
  @include font-size($input-font-size-sm);
  line-height: $input-line-height-sm;
  @include border-radius($input-border-radius-sm);
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: $custom-select-padding-x + $custom-select-indicator-padding;
}

// Prepend and append rounded corners
//
// These rulesets must come after the sizing ones to properly override sm and lg
// border-radius values when extending. They're more specific than we'd like
// with the `.input-group >` part, but without it, we cannot override the sizing.

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.count-message {
  margin-top: -30px;
  margin-right: 10px;
  font-size: 14px;
  z-index: 100;
  position: relative;
  background: $white;
}
.onboarding-image {
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.4);
  border-radius: $input-border-radius;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
  }
}
.calendar-sidebar,
.pricing-table,
.form-group {
  input[type='checkbox'] {
    border-radius: $input-border-radius;
    width: 1.065rem;
    height: 1.065rem;
    accent-color: $primary-dark;

    &.form-check-input {
      margin-top: 0rem;
      accent-color: $primary;
      &.meeting-type-0 {
        accent-color: $type-0 !important;
      }
      &.meeting-type-1 {
        accent-color: $type-1 !important;
      }
      &.meeting-type-2 {
        accent-color: $type-2 !important;
      }
      &.meeting-type-3 {
        accent-color: $type-3 !important;
      }
      &.meeting-type-4 {
        accent-color: $type-4 !important;
      }
      &.meeting-type-5 {
        accent-color: $type-5 !important;
      }
      &.meeting-type-6 {
        accent-color: $type-6 !important;
      }
      &.meeting-type-7 {
        accent-color: $type-7 !important;
      }
      &.meeting-type-8 {
        accent-color: $type-8 !important;
      }
      &.meeting-type-9 {
        accent-color: $type-9 !important;
      }
    }
  }
  input[type='radio'] {
    &.form-check-input {
      margin-top: 0.2rem;
      font: inherit;
      appearance: none;
      color: $primary;
      width: 16px;
      height: 16px;
      border: 1px solid $gray-700;
      border-radius: 50%;
      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        transition: 120ms transform ease-in-out;
        box-shadow: inset 1em 1em $primary;
        transform: scale(0) translateY(-100%);
        /* Windows High Contrast Mode */
        background-color: $primary;
      }
      &:checked {
        border: 1px solid $primary;

        &:before {
          transform: scale(1) translateY(-50%);
        }
      }
    }
  }
}
