.toolbar {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px;
  border-radius: 1.5rem;
  z-index: 1;
  min-width: 0;
  word-wrap: break-word;
  scroll-behavior: smooth;

}

.editor {
  position: relative; // Ensure relative positioning within the card
  overflow: auto;
  outline: none;
  font-size: large;

  &:empty:before {
    content: attr(data-placeholder); /* Placeholder text */
    color: $gray-600; /* Placeholder text color */
    pointer-events: none; /* Ensures the placeholder text does not interfere with cursor placement */
  }
  h2{
    margin-bottom: 10px;
    margin-top:5px;
    line-height: 1.25em;
    font-weight: 600;
  }
  a{
    color: $primary; 
  }
  ol, ul, dl, div, p{
    font-size: large;
    margin-bottom: 5px;
    font-weight: normal;
  }
}

.toolbar-popover{
  .popover{
    padding:0px;
    background: $white;
    max-width: 100%;
.popover-inner{
  display: block;  
  overflow: hidden;
  border-radius: 1.5rem;

    .popover-body{
      padding: 0px;
      border-radius: 1.5rem;
    }
  }
    .arrow {
      &::after{
        border-top-color: $white !important;
        border-bottom-color: $white !important;
      }
    }
    .emoji-mart{
      border: none;
      .emoji-mart-bar{
        border-top-width: 0px;
      }
    }
  }
} 

.editor-button {
  padding: $btn-padding-y $btn-padding-x-sm ;
  cursor: pointer;
  background-color: $white;
  border: none;
  width: 48px;
  height: 48px;
  background-color: transparent;
  color: $default;

  i{
    height: 17px;
    vertical-align: middle;
    display: inline-block;
    color: $default;
  }
  &:hover {
    background-color: $gray-200;
  }

  &.active {
    background-color: $default; // Light gray color when active
    i{
      color: $white;
    }
  }
} 
.emoji-mart-anchor{
  &:hover, &:focus {
    color: $default !important;
  }
}
.emoji-mart-anchor-selected{
  color: $default !important;
  .emoji-mart-anchor-bar{
    background-color: $default !important;
  } 
}
.emoji-mart-preview{
  display: none;
}
.emoji-mart-search{
  margin-bottom: 10px;
.emoji-mart-search-icon {
  top: 0px !important;
  height: 28.39px;
}
}
.emoji-mart-category-label{
  span{
    padding: 10px 6px !important;
  }
}
